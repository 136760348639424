<template>
    <v-row no-gutters>
        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                item-key="tokenid"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                @dblclick:row="rowClick"
                class="elevation-1 customization-list-table pagination-list-table sticky-table">

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                

                <template v-slot:[`item.login`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.login">
                                {{item.login}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.name">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.enterprise_name`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.enterprise_name">
                                {{item.enterprise_name}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.lastlogin`]="{ item }">
                    {{ item.lastlogin ? $moment(item.lastlogin).format('DD.MM.YYYY HH:mm:ss') : '' }}
                </template>

                <template v-slot:[`item.lastactivity`]="{ item }">
                    {{ item.lastactivity ? $moment(item.lastactivity).format('DD.MM.YYYY HH:mm:ss') : '' }}
                </template>
                
                <template v-slot:[`item.level_access`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-if="item.login" v-tooltip.top-center="levelAccessValue(item)">
                                {{levelAccessValue(item)}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.contacts`]="{ item }">
                    <div class="onlyReadData more-per-lab-wrap" v-if="item.contacts">
                        <v-contact-item
                            v-for="contact in item.contacts"
                            :key="contact.id"
                            :type="contact.type === 'email' ? 'email' : 'phone'"
                            :title="contact.value"
                            :description="contact.description"
                            :hide-load="true"
                            :is-read-only="true"
                        />
                    </div>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <StatusChip :status="avaibleStatuses[$helpers.getEmployeeStatus(item)]"/>       
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="table-action-menu-col-with-header" v-on:click.stop.prevent>
                        <v-popover
                            v-if="$helpers.getEmployeeActions(item).length > 0 && getUserInfo.levelAccess>=item.level_access"
                            trigger="click"
                        >
                            <v-chip
                                class="person-label"
                                small
                                label
                                color="orange lighten-3"
                            >
                                <v-icon color="#494F59">fas fa-ellipsis-v</v-icon>
                            </v-chip>

                            <template slot="popover">
                                <ul class="ribbon-button-submenu">
                                    <li 
                                        v-for="action in $helpers.getEmployeeActions(item)"
                                        :key="action.Action"
                                        @click="onInvokeEmployeeAction(action.Action, item)"
                                        v-close-popover
                                    >
                                        <v-icon left>
                                            {{ action.Icon }}
                                        </v-icon>

                                        {{ action.Text }}
                                    </li>
                                </ul>
                                <span class="ribbon-button-submenu-title">{{ $t("Подменю_действий") }}</span>
                            </template>
                        </v-popover>
                    </div>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>
    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapGetters } from 'vuex';
import StatusChip from '@/components/StatusChip';

export default {
    name: "LockedUsers",
    columnIndex:  {
        'name': 4,
        'login': 6,
        'enterprise_name': 5,
        'lastlogin': 10,
        'lastactivity': 9,
        'level_access': 11,
        'contacts': 13
    },
    props: {
        search: {
            type: String,
            default: ""
        }
    },
    components: {
        StatusChip
    },
    data() {
        return {
            loading: true,
            headers: [
                { text: i18n.t("Логин"), value: 'login', width: '15%' },
                { text: i18n.t("ФИО"), value: 'name', width: '15%' },
                { text: i18n.t("Организация"), value: 'enterprise_name' },                
                { text: i18n.t("Последний_вход"), value: 'lastlogin', width: '150px' },
                { text: i18n.t("Последняя_активность"), value: 'lastactivity', width: '180px' },
                { text: i18n.t("Уровень_доступа"), value: 'level_access', width: '10%' },
                { text: i18n.t("Контакты"), value: 'contacts', width: '15%' },
                { text: i18n.t("Статус"), value: 'status', width: '36px', sortable: false },
                { text: i18n.t("Действие"), value: 'actions', width: '36px', sortable: false },
            ],
            total: 0,
            cancellationTokenSorce: null,
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        ...mapGetters('references', ['GetSecurityLevelAccess']),
        ...mapGetters('auth', ['getUserInfo']),
        options: {
            get: function() {
                return this.$store.getters['users/lockedusers/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('users/lockedusers/SET_TABLE_OPTION', newValue);
            }
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search
            }
        }
    },
    methods: {
        resetPage() {
            this.$store.commit('users/lockedusers/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData (isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getSerializedFilter()))
                return;

            this.prevTableFilterObject = this.getSerializedFilter();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/ems/userslist?filter=${this.getSerializedFilter()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
        },
        getSerializedFilter() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;

            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "fio" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 2;

            return JSON.stringify(
            { 
                SearchStr,
                OrderBy,
                OrderDir,
                Filter:'Locked',
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            });
        },
        levelAccessValue(item){
            if (item.login){
                if (item.level_access)
                    return this.GetSecurityLevelAccess.find(level=>level.id == item.level_access)?.Value;
                else 
                    return this.GetSecurityLevelAccess.find(level=>level.id == 0)?.Value;
            }
        },
        rowClick(item, row) {
            this.$router.push({ name: 'EMSEmployeeCard', params: { id: this.entities[row.index].workplace_id } });
        },
        async onToolbarClick(event, btn) {
            await this[btn.Action](btn);
        },
        async onInvokeEmployeeAction(action, item) {
            await this[action](item);
        },
        async EmployeeDropToken(item){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: `/api/security/dropemployeetoken?id=${item.employee_id}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

            if (response) {
                await this.getData(true);
                this.$notify.success(response.data.payload.Message);
            }
        },
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>